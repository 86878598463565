<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-catalog"
    ref="modal-catalog"
    :title="principal_Id ? `Edit ${catalogName}` : `New ${catalogName}` "
    no-close-on-backdrop
    :hide-footer="true"
    centered
    @hidden="resetModal"
  >
    <div>
      <b-form-group
        v-show="Model.Name.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Name.text"
        label-for="input-sm"
      >
        <b-form-input
          id="input-sm"
          v-model="Model.Name.value"
          size="sm"
          placeholder="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.LastName.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.LastName.text"
        label-for="input-sm"
      >
        <b-form-input
          id="input-sm"
          v-model="Model.LastName.value"
          size="sm"
          placeholder="Last Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.Description.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Description.text"
        label-for="input-sm"
      >
        <b-form-textarea
          id="textarea-rows"
          v-model="Model.Description.value"
          placeholder="Description textarea"
          rows="3"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.SingleSelect1.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.SingleSelect1.text"
        label-for="input-sm"
      >
        <v-select
          v-model="Model.SingleSelect1.value"
          class="select-size-sm"
          :options="optionSingle1"
          label="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.SingleSelect2.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.SingleSelect2.text"
        label-for="input-sm"
      >
        <v-select
          v-model="Model.SingleSelect2.value"
          class="select-size-sm"
          :options="optionSingle2"
          label="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.MultipleSelect1.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.MultipleSelect1.text"
        label-for="input-sm"
      >
        <v-select
          v-model="Model.MultipleSelect1.value"
          multiple
          class="select-size-sm"
          :options="optionMultiple1"
          label="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.MultipleSelect2.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.MultipleSelect2.text"
        label-for="input-sm"
      >
        <v-select
          v-model="Model.MultipleSelect2.value"
          multiple
          class="select-size-sm"
          :options="optionMultiple2"
          label="Name"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.Time1.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Time1.text"
        label-for="input-sm"
      >
        <b-form-timepicker
          v-model="Model.Time1.value"
          locale="en"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.Time2.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Time2.text"
        label-for="input-sm"
      >
        <b-form-timepicker
          v-model="Model.Time2.value"
          locale="en"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.InputNumer1.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.InputNumer1.text"
        label-for="input-sm"
      >
        <b-form-input
          id="input-sm"
          v-model="Model.InputNumer1.value"
          type="number"
          size="sm"
          placeholder="Input Number 1"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.InputNumer2.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.InputNumer2.text"
        label-for="input-sm"
      >
        <b-form-input
          id="input-sm"
          v-model="Model.InputNumer2.value"
          type="number"
          size="sm"
          placeholder="Input Number 2"
        />
      </b-form-group>

      <b-form-group
        v-if="Model.Date.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.Date.text"
        label-for="input-sm"
      >
        <flat-pickr
          v-model="Model.Date.value"
          class="form-control"
        />
      </b-form-group>
      <b-form-group
        v-if="Model.AvailableDays.active"
        label-cols="4"
        label-cols-lg="2"
        label-size="sm"
        :label="Model.AvailableDays.text"
        label-for="input-sm"
      >
        <b-form-checkbox-group
          v-model="Model.AvailableDays.value"
          :options="optionsDays"
          name="flavour-1a"
          class="demo-inline-spacing"
        />
      </b-form-group>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        block
        variant="primary"
        @click="CreateUpdateElement()"
      >
        {{ principal_Id ? `Edit` : `Save` }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BFormGroup, BFormInput, BFormTextarea, BFormTimepicker, BButton, BFormCheckboxGroup,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import catalogServices from '@core/services/catalogs/catalogService'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BFormTimepicker,
    flatPickr,
    BFormCheckboxGroup,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    refreshCatalog: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      catalogName: '',
      principal_Id: 0,
      optionSingle1: [],
      optionSingle2: [],
      optionMultiple1: [],
      optionMultiple2: [],
      Model: {
        Name: { active: false, value: null, text: '' }, LastName: { active: false, value: null, text: '' }, Description: { active: false, value: null, text: '' }, SingleSelect1: { active: false, value: null, text: '' }, SingleSelect2: { active: false, value: null, text: '' }, MultipleSelect1: { active: false, value: null, text: '' }, MultipleSelect2: { active: false, value: null, text: '' }, Time1: { active: false, value: null, text: '' }, Time2: { active: false, value: null, text: '' }, InputNumer1: { active: false, value: null, text: '' }, InputNumer2: { active: false, value: null, text: '' }, Date: { active: false, value: null, text: '' }, AvailableDays: { active: false, value: [], text: '' },
      },
      Selected: [],
      optionsDays: [
        { text: 'SU', value: 'SU' },
        { text: 'MO', value: 'MO' },
        { text: 'TU', value: 'TU' },
        { text: 'WE', value: 'WE' },
        { text: 'TH', value: 'TH' },
        { text: 'FR', value: 'FR' },
        { text: 'SA', value: 'SA' },
      ],
      // Name: { active: false, value: null, text: '' }, LastName: { active: false, value: null, text: '' }, Description: { active: false, value: null, text: '' }, SingleSelect1: { active: false, value: null, text: '' }, SingleSelect2: { active: false, value: null, text: '' }, MultipleSelect1: { active: false, value: null, text: '' }, MultipleSelect2: { active: false, value: null, text: '' }, Time1: { active: false, value: null, text: '' }, Time2: { active: false, value: null, text: '' }, InputNumer1: { active: false, value: null, text: '' }, InputNumer2: { active: false, value: null, text: '' }, Date: { active: false, value: null, text: '' },
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  created() {
    this.$bus.$on('setParamsModalCatalog', param => {
      const { catalog, data } = param
      this.resetModal()
      this.SetData(catalog, data)
      this.catalogName = catalog
      if (!data) {
        this.principal_Id = 0
      } else {
        this.principal_Id = data.Id
      }
    })
  },
  methods: {
    resetModal() {
      this.Model = {
        Name: { active: false, value: null, text: '' }, LastName: { active: false, value: null, text: '' }, Description: { active: false, value: null, text: '' }, SingleSelect1: { active: false, value: null, text: '' }, SingleSelect2: { active: false, value: null, text: '' }, MultipleSelect1: { active: false, value: null, text: '' }, MultipleSelect2: { active: false, value: null, text: '' }, Time1: { active: false, value: null, text: '' }, Time2: { active: false, value: null, text: '' }, InputNumer1: { active: false, value: null, text: '' }, InputNumer2: { active: false, value: null, text: '' }, Date: { active: false, value: null, text: '' }, AvailableDays: { active: false, value: [], text: '' },
      }
    },
    async SetData(catalog, data) {
      switch (catalog) {
        case 'HealthPlan':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.Description.active = true
          this.Model.Description.text = 'Description'
          if (data) {
            this.Model.Name.value = data.Name
            this.Model.Description.value = data.Description
          }
          break
        case 'Ipa':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.Description.active = true
          this.Model.Description.text = 'Description'
          if (data) {
            this.Model.Name.value = data.Name
            this.Model.Description.value = data.Description
          }
          break
        case 'PrimaryCareProvider':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.Description.active = true
          this.Model.Description.text = 'Description'
          if (data) {
            this.Model.Name.value = data.Name
            this.Model.Description.value = data.Description
          }
          break
        case 'Site':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.Description.active = true
          this.Model.Description.text = 'Description'
          this.Model.MultipleSelect1.active = true
          this.Model.MultipleSelect1.text = 'Providers'
          this.Model.Time1.active = true
          this.Model.Time1.text = 'Beggining Hour'
          this.Model.Time2.active = true
          this.Model.Time2.text = 'Finishing Hour'
          this.Model.AvailableDays.active = true
          this.Model.AvailableDays.text = 'Working Days'
          this.optionMultiple1 = await this.reloadSelect('Provider')
          if (data) {
            this.Model.Name.value = data.Name
            this.Model.Description.value = data.Description
            this.Model.MultipleSelect1.value = data.providers
            this.Model.Time1.value = data.BeginHr
            this.Model.Time2.value = data.EndHr
            const days = data.AvailableDays.split('/')
            this.Model.AvailableDays.value = days
          }
          break
        case 'ProviderType':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          if (data) {
            this.Model.Name.value = data.Name
          }
          break
        case 'Provider':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.LastName.active = true
          this.Model.LastName.text = 'Last Name'
          this.Model.Description.active = true
          this.Model.Description.text = 'Notes'
          this.Model.SingleSelect1.active = true
          this.Model.SingleSelect1.text = 'Provider Type'
          this.Model.MultipleSelect1.active = true
          this.Model.MultipleSelect1.text = 'Select Site'
          this.Model.MultipleSelect2.active = true
          this.Model.MultipleSelect2.text = 'Speciality'
          this.optionSingle1 = await this.reloadSelect('ProviderType')
          this.optionMultiple1 = await this.reloadSelect('Site')
          this.optionMultiple2 = await this.reloadSelect('Speciality')
          if (data) {
            this.Model.Name.value = data.ProviderFirstName
            this.Model.LastName.value = data.ProviderLastName
            this.Model.Description.value = data.ProviderDescription
            this.Model.SingleSelect1.value = data.type
            this.Model.MultipleSelect1.value = data.sites
            this.Model.MultipleSelect2.value = data.specs
          }
          break
        case 'ProviderSite':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.SingleSelect1.active = true
          this.Model.SingleSelect1.text = 'Name Site'
          this.optionSingle1 = await this.reloadSelect('Site')
          if (data) {
            this.Model.Name.value = data.NameProvider
            this.Model.SingleSelect1.value = data.Site
          }
          break
        case 'ProviderSpeciality':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.SingleSelect1.active = true
          this.Model.SingleSelect1.text = 'Select Speciality'
          this.optionSingle1 = await this.reloadSelect('Speciality')
          if (data) {
            this.Model.Name.value = data.NameProvider
            this.Model.SingleSelect1.value = data.spec
          }
          break
        case 'VisitType':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.Description.active = true
          this.Model.Description.text = 'Description'
          this.Model.InputNumer1.active = true
          this.Model.InputNumer1.text = 'Appointment Future Limit (Days)'
          this.Model.InputNumer2.active = true
          this.Model.InputNumer2.text = 'Appointment Past Limit (Days)'
          this.Model.SingleSelect1.active = true
          this.Model.SingleSelect1.text = 'Service'
          this.optionSingle1 = await this.reloadSelect('VisitTypeGroup')
          if (data) {
            this.Model.Name.value = data.VisitTypeName
            this.Model.Description.value = data.VisitTypeDescription
            this.Model.SingleSelect1.value = data.service
            this.Model.InputNumer1.value = data.VisitTypeFutureLimit
            this.Model.InputNumer2.value = data.VisitTypePastLimit
          }
          break
        case 'VisitTypeGroup':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          if (data) {
            this.Model.Name.value = data.Name
          }
          break
        case 'Sources':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.Description.active = true
          this.Model.Description.text = 'Description'
          if (data) {
            this.Model.Name.value = data.Name
            this.Model.Description.value = data.Description
          }
          break
        case 'State':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          if (data) {
            this.Model.Name.value = data.Name
          }
          break
        case 'InteractionType':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          if (data) {
            this.Model.Name.value = data.Name
          }
          break
        case 'Speciality':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          if (data) {
            this.Model.Name.value = data.Name
          }
          break
        case 'Gender':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          if (data) {
            this.Model.Name.value = data.Name
          }
          break
        case 'CoverageType':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          if (data) {
            this.Model.Name.value = data.Name
          }
          break
        case 'City':
          this.Model.Name.active = true
          this.Model.Name.text = 'Name'
          this.Model.SingleSelect1.active = true
          this.Model.SingleSelect1.text = 'Select State'
          this.optionSingle1 = await this.reloadSelect('State')
          if (data) {
            this.Model.Name.value = data.CityName
            this.Model.SingleSelect1.value = data.state
          }
          break
        default:
      }
    },
    async reloadSelect(catalog) {
      return catalogServices.getCatalogSelect({
        table: catalog,
      }).then(response => {
        const selectoption = response.data.rows
        return selectoption
      })
    },
    /* eslint-disable */
    Valid() {
      let Text = ''
      for (const obj in this.Model) {
        if (this.Model[obj].active === true) {
          if (!this.Model[obj].value || this.Model[obj].value === null || this.Model[obj].value.length === 0) {
            Text += `-${this.Model[obj].text} missing<br>`
          }
        }
      }
      if (Text !== '') {
        Swal.fire({
          icon: 'error',
          title: 'Profile Error',
          html: `<div class="lbedo">${Text}</div>`,
          showConfirmButton: false,
        })
        return false
      } else {
        return true
      }
    },
    /* eslint-enable */
    CreateUpdateElement() {
      const complete = this.Valid()
      if (complete) {
        Swal.fire({
          title: 'Attention',
          text: `¿Do you want to ${this.principal_Id ? 'edit' : 'save'} this element?`,
          icon: 'warning',
          confirmButtonText: 'Yes',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
        }).then(result => {
          if (result.isConfirmed) {
            switch (this.catalogName) {
              case 'HealthPlan':
                this.editcreateNameDesc()
                break
              case 'Ipa':
                this.editcreateNameDesc()
                break
              case 'PrimaryCareProvider':
                this.editcreateNameDesc()
                break
              case 'Site':
                this.editcreateSite()
                break
              case 'ProviderType':
                this.editcreateName()
                break
              case 'Provider':
                this.editcreateProvider()
                break
              case 'VisitType':
                this.editcreateVisitType()
                break
              case 'VisitTypeGroup':
                this.editcreateName()
                break
              case 'Sources':
                this.editcreateNameDesc()
                break
              case 'State':
                this.editcreateName()
                break
              case 'InteractionType':
                this.editcreateName()
                break
              case 'Speciality':
                this.editcreateName()
                break
              case 'Gender':
                this.editcreateName()
                break
              case 'CoverageType':
                this.editcreateName()
                break
              case 'City':
                this.editcreateCity()
                break
              default:
            }
          }
        })
      }
    },
    editcreateNameDesc() {
      catalogServices.createUpdateElement({
        table: this.catalogName,
        id: this.principal_Id,
        Name: this.Model.Name.value,
        Description: this.Model.Description.value,
      }).then(response => {
        this.toastSuccessEdit(response.data)
        this.refreshCatalog(this.catalogName)
        this.$root.$emit('bv::hide::modal', 'modal-catalog')
      })
        .catch(error => {
          this.toastErrorHandler(error)
        })
    },
    toastSuccessEdit(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          text: message.data,
          icon: 'CheckSquareIcon',
          variant: 'success',
        },
      })
    },
    editcreateName() {
      catalogServices.createUpdateElement({
        table: this.catalogName,
        id: this.principal_Id,
        Name: this.Model.Name.value,
      }).then(response => {
        this.toastSuccessEdit(response.data)
        this.refreshCatalog(this.catalogName)
        this.$root.$emit('bv::hide::modal', 'modal-catalog')
      })
        .catch(error => {
          this.toastErrorHandler(error)
        })
    },
    editcreateCity() {
      catalogServices.createUpdateElement({
        table: this.catalogName,
        id: this.principal_Id,
        Name: this.Model.Name.value,
        IdState: this.Model.SingleSelect1.value.Id,
      }).then(response => {
        this.toastSuccessEdit(response.data)
        this.refreshCatalog(this.catalogName)
        this.$root.$emit('bv::hide::modal', 'modal-catalog')
      })
        .catch(error => {
          this.toastErrorHandler(error)
        })
    },
    editcreateSite() {
      let Texti = ''
      this.sortDays(this.Model.AvailableDays.value).forEach(element => {
        Texti += `${element}/`
      })
      const editedText = Texti.slice(0, -1)
      catalogServices.createUpdateSite({
        id: this.principal_Id,
        Name: this.Model.Name.value,
        Description: this.Model.Description.value,
        IdProvider: this.Model.MultipleSelect1.value,
        BeginHr: this.Model.Time1.value,
        EndHr: this.Model.Time2.value,
        AvailableDays: editedText,
      }).then(response => {
        this.toastSuccessEdit(response.data)
        this.refreshCatalog(this.catalogName)
        this.$root.$emit('bv::hide::modal', 'modal-catalog')
      })
        .catch(error => {
          this.toastErrorHandler(error)
        })
    },
    sortDays(toSort) {
      const today = new Date().toUTCString().substr(0, 0) // get today as 3 letter string
      let list = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'] // days list
      const before = list.splice(0, list.indexOf(today)) // splice what is before today in the list
      list = list.concat(before) // concat the list with what was spliced
      return list.filter(item => toSort.indexOf(item) !== -1) // return the sorted list with only the asked days
    },
    editcreateVisitType() {
      catalogServices.createUpdateElement({
        table: this.catalogName,
        id: this.principal_Id,
        Name: this.Model.Name.value,
        Description: this.Model.Description.value,
        FutureLimit: this.Model.InputNumer1.value,
        PastLimit: this.Model.InputNumer2.value,
        IdVisitTypeGroup: this.Model.SingleSelect1.value.Id,
      }).then(response => {
        this.toastSuccessEdit(response.data)
        this.refreshCatalog(this.catalogName)
        this.$root.$emit('bv::hide::modal', 'modal-catalog')
      })
        .catch(error => {
          this.toastErrorHandler(error)
        })
    },
    editcreateProvider() {
      catalogServices.createUpdateProvider({
        id: this.principal_Id,
        FirstName: this.Model.Name.value,
        LastName: this.Model.LastName.value,
        Description: this.Model.Description.value,
        IdProviderType: this.Model.SingleSelect1.value.Id,
        IdSite: this.Model.MultipleSelect1.value,
        IdSpeciality: this.Model.MultipleSelect2.value,
      }).then(response => {
        this.toastSuccessEdit(response.data)
        this.refreshCatalog(this.catalogName)
        this.$root.$emit('bv::hide::modal', 'modal-catalog')
      })
        .catch(error => {
          this.toastErrorHandler(error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
