import axiosService from '../axios/axiosService'
import jwtDefaultConfig from '../../auth/jwt/jwtDefaultConfig'

class CatalogService {
    // jwtConfig <= Will be used by this service
    jwtConfig = { ...jwtDefaultConfig }

    getAll(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getAll, ...args)
    }

    deleteElement(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.deleteElement, ...args)
    }

    getOneElement(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getOneElement, ...args)
    }

    async getCatalogSelect(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.getCatalogSelect, ...args)
    }

    createUpdateElement(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createUpdateElement, ...args)
    }

    createUpdateProvider(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createUpdateProvider, ...args)
    }

    createUpdateSite(...args) {
      return axiosService.axiosIns.post(this.jwtConfig.createUpdateSite, ...args)
    }
}

function useCatalogService() {
  const catalogService = new CatalogService()

  return {
    catalogService,
  }
}

const { catalogService } = useCatalogService()
export default catalogService
