<template>
  <div>
    <b-card>
      <b-tabs
        content-class="col-12 col-md-9 mt-1 mt-md-0"
        pills
        nav-wrapper-class="col-md-3 col-12"
        nav-class="nav-left"
        vertical
      >
        <!-- general tab -->
        <b-tab
          v-for="(catalog, index) in Object.keys(CatalogsData)"
          :key="CatalogsData[catalog].table"
          :index="index"
        >
          <template #title>
            <span
              class="font-weight-bold"
              style="font-size: 12px !important;"
            > {{ CatalogsData[catalog].table | catalog-dictionary }} </span>
          </template>
          <table-catalog
            :data-catalog="CatalogsData[catalog]"
            :refresh-catalog="getSpecificCatalog"
          />
        </b-tab>
        <!-- general tab -->
      </b-tabs>
    </b-card>
    <modal-catalog
      :refresh-catalog="getSpecificCatalog"
    />
  </div>
</template>

<script>
import {
  BCard, BTabs, BTab,
} from 'bootstrap-vue'
import catalogServices from '@core/services/catalogs/catalogService'
import TableCatalog from './TableCatalog.vue'
import ModalCatalog from './Modal/CatalogsModal.vue'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    TableCatalog,
    ModalCatalog,
  },
  data() {
    return {
      Catalogs: [
        /* 'campaign',
        'campaign_observations',
        'citas_cambios_motivos',
        'ciudades',
        'clinics',
        'estados',
        'gender',
        'healthplans',
        'interaction',
        'ipas',
        'patient_status_campaign',
        'primarycareproviders',
        'providers',
        'providers_sites',
        'providers_specialities',
        'providers_type',
        'representatives',
        'rescheduled_by_catalog',
        'service_groups',
        'services',
        'sources',
        'type_representative',
        'programs', */
        'Site',
        'HealthPlan',
        'Ipa',
        'PrimaryCareProvider',
        'Provider',
        'ProviderType',
        'VisitType',
        'VisitTypeGroup',
        'Source',
        'State',
        'City',
        'InteractionType',
        'CoverageType',
        'Gender',
        'Speciality',
      ],
      CatalogsData: {
        Site: {},
        HealthPlan: {},
        Ipa: {},
        PrimaryCareProvider: {},
        Provider: {},
        ProviderType: {},
        VisitType: {},
        VisitTypeGroup: {},
        Source: {},
        State: {},
        City: {},
        InteractionType: {},
        CoverageType: {},
        Gender: {},
        Speciality: {},
      },

    }
  },
  /* watch: {
    CatalogsData: {
      immediate: true,
      handler(val) {
      },
      deep: true,
    },
  }, */
  created() {
    this.getAllCatalogs()
  },
  mounted() {
  },
  methods: {
    getAllCatalogs() {
      this.Catalogs.forEach(catalog => {
        catalogServices.getAll({
          table: catalog,
        }).then(response => {
          this.CatalogsData[catalog] = response.data.data
        })
      })
    },
    /* eslint-disable */
    getSpecificCatalog(catalog) {
      catalogServices.getAll({
        table: catalog,
      }).then(response => {
        this.CatalogsData[catalog] = response.data.data
      })
    },
    /* eslint-enable */
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
</style>
<style>
.nav-pills .nav-link{
    padding: 10px !important;
}
.vs__deselect {
  background-color: aliceblue !important;
}
</style>
