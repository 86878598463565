<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          style="padding-left: 0px;"
        >
          <b-button
            variant="primary"
            @click="OpenModal()"
          >
            Add {{ dataCatalog.table | catalog-dictionary }}
          </b-button>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div
            class="d-flex align-items-center justify-content-end"
            style="margin-right: 0px; padding-right: 0px;"
          >
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block"
              placeholder="Search..."
              @input="handleSearch"
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      :items="dataCatalog.rows"
      :fields="fields"
      striped
      responsive
      style="position: relative !important; height: 600px !important;"
    >

      <template #cell(Actions)="data">
        <!-- {{ Object.keys(data.item)[0] }} {{ Object.values(data.item)[0] }} -->
        <span class="text-nowrap">
          <feather-icon
            :id="`action-row-${Object.keys(data.item)[0]}-${Object.values(data.item)[0]}-edit-icon`"
            icon="EditIcon"
            class="cursor-pointer"
            size="16"
            @click="OpenModal(Object.values(data.item)[0])"
          />
          <b-tooltip
            title="Edit"
            :target="`action-row-${Object.keys(data.item)[0]}-${Object.values(data.item)[0]}-edit-icon`"
          />

          <feather-icon
            :id="`action-row-${Object.keys(data.item)[0]}-${Object.values(data.item)[0]}-delete-icon`"
            icon="TrashIcon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="deleteElement(dataCatalog.table, Object.values(data.item)[0])"
          />
          <b-tooltip
            title="Delete"
            :target="`action-row-${Object.keys(data.item)[0]}-${Object.values(data.item)[0]}-delete-icon`"
          />
        </span>
      </template>

      <!-- Sites -->
      <template #cell(providers)="data">
        <span class="text-nowrap">
          <b-button
            :id="`popover-${data.item.Id}`"
            v-b-popover.hover.focus
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon"
            size="sm"
          >
            <feather-icon icon="UserIcon" />
          </b-button>

          <b-popover
            ref="popover-show"
            :target="`popover-${data.item.Id}`"
            triggers="click hover"
          >
            <template #title>
              Providers
            </template>
            <b-badge
              v-for="(provider, index) in data.value"
              :key="index"
              pill
              class="text-capitalize mr-1"
            >
              {{ provider.Name }}
            </b-badge>
          </b-popover>
        </span>
      </template>

      <!-- Providers -->
      <template #cell(ProviderFirstName)="data">
        <span class="text-nowrap">
          {{ data.value }} {{ data.item.ProviderLastName }}
        </span>
      </template>

      <template #cell(ProviderActive)="data">
        <span class="text-nowrap">
          <b-button
            :style="{'background-color' : data.value === 1 ? '#1FE01F' + '!important' : '#E82222' + '!important' }"
            size="sm"
          >
            {{ data.value === 1 ? 'Active' : 'Inactive' }}
          </b-button>
        </span>
      </template>

      <template #cell(sites)="data">
        <b-badge
          v-for="(sites, index) in data.value"
          :key="index"
          pill
          class="text-capitalize mr-1"
          variant="primary"
        >
          {{ sites.Name }}
        </b-badge>
      </template>

      <template #cell(specs)="data">
        <b-badge
          v-for="(spec, index) in data.value"
          :key="index"
          pill
          class="text-capitalize mr-1"
          variant="primary"
        >
          {{ spec.Name }}
        </b-badge>
      </template>

      <!-- Campaign Patient Status -->

      <template #cell()="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>
      <!-- Column: Default -->

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <!-- <span class="text-muted">Showing {{ perPage }} of {{ FullCountEntries }} entries</span> -->
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BTooltip, BBadge, VBPopover, BPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import catalogServices from '@core/services/catalogs/catalogService'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BTooltip,
    BBadge,
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    dataCatalog: {
      type: Object,
      required: true,
    },
    refreshCatalog: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      items: [],
      searchTerm: '',
      dictionaryLabelName: {
        ProviderFirstName: 'Provider Name',
      },
    }
  },
  watch: {
  },
  created() {
    this.CleanDataUnnecessary()
  },
  methods: {
    /* eslint-disable */
    OpenModal(id) {
      if (id) {
        catalogServices.getOneElement({
          table: this.dataCatalog.table,
          id,
        }).then(response => {
          const data = response.data.data[0] === null ? null : response.data.data[0]
          this.$bus.$emit('setParamsModalCatalog', { catalog: this.dataCatalog.table, data })
          this.$root.$emit('bv::show::modal', 'modal-catalog')
        })
      } else {
        this.$bus.$emit('setParamsModalCatalog', { catalog: this.dataCatalog.table, data: null })
        this.$root.$emit('bv::show::modal', 'modal-catalog')
      }
      
    },
    /* eslint-enable */
    /* eslint-disable */
    CleanDataUnnecessary() {
      if(!this.dataCatalog.rows) return
      const DataClean = []
      this.dataCatalog.rows.forEach((element, index) => {
        const obj = this.dataCatalog.rows[index]
        Object.keys(obj).filter(key => {
          if (key.startsWith('Id') || key.includes('ProviderLastName') || key.includes('Id') || key.startsWith('status') || key.startsWith('estatus')) {
        
          } else {
            let textlabel = this.dictionaryLabelName[key] == null ? null : this.dictionaryLabelName[key]
            DataClean.push({'key': key, 'sortable': true, 'label': textlabel})
          }
        }).length > 0
      });
      DataClean.push('Actions')
      this.fields = DataClean
      // console.log(this.fields)
    },
    /* eslint-enable */
    handleSearch(searching) {
      this.searchTerm = searching
      this.actualpage = 1
      this.totalRows = 1
      this.getProfiles()
    },
    deleteElement(catalog, id) {
      Swal.fire({
        title: 'Attention',
        text: 'Do you want to delete this record',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then(result => {
        if (result.isConfirmed) {
          catalogServices.deleteElement({
            table: catalog,
            id,
          }).then(response => {
            this.toastSuccessEdit(response.data)
            this.refreshCatalog(catalog)
          })
        }
      })
    },
    toastSuccessEdit(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          text: message.data,
          icon: 'CheckSquareIcon',
          variant: 'success',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">

</style>
